.sidebarContainer {
  .iconBack {
    position: absolute;
    top: 55px;
    right: 0px;
    transform: translateX(50%) translateY(-50%);
    width: 40px;
    height: 40px;
    padding: 9px;
    border-radius: 50%;
    border: none;
    color: rgb(255, 255, 255);
    background: none;
    cursor: pointer;
  }
  .listContainer {
    position: relative;
  }
}
