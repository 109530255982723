$black-color: #030321;
$red-color: #d32f2f;
$green-color: #20c198;
$light-red: #f0ddde;
$light-green: #d6eee8;
$blue-color: #1d439b;

:export {
  redColor: $red-color;
  blueColor: $blue-color;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6 !important;
}

::-webkit-scrollbar {
  width: 7px; /* for vertical scroll bars */
  height: 7px; /* for horizontal scroll bars */
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #e4e6e8;
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Roboto" !important;
  color: #0f162e;
}
.MuiContainer-root {
  padding-top: 25px !important;
}

@media screen and (max-width: 790px) {
  .MuiContainer-root {
    padding-inline: 20px !important;
  }
}

@media screen and (max-width: 650px) {
  .MuiContainer-root {
    padding-inline: 16px !important;
  }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.MuiOutlinedInput-notchedOutline legend {
  font-size: 0.85em !important;
}

// Toast message
.Toastify__toast--success {
  background: #E5F3F0 !important;
}

.Toastify__toast--error {
  background: #FDEBE9 !important;
}