.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .main {
    height: 100%;
  }
}
